
import StringsMagazinePDF from '../../../data/StringsMagazine.pdf';

const BlogContent = [
    {
        images: 'legend.png',
        title: 'Stella Chen Debuts as SF Symphony Concerto Soloist',
        category: 'The Rehearsal Studio',
        link: 'https://therehearsalstudio.blogspot.com/2024/07/sfs-stella-chen-debuts-as-concerto.html'
    },
    {
        images: 'florida-orchestra.jpg',
        title: 'Violinist Tunes up a Rarity for Barber Concerto',
        category: 'Florida Orchestra',
        link: 'https://floridaorchestra.org/violinist-tunes-up-for-barber-concerto/'
    },
    {
        images: 'trio.png',
        title: "String Trio To Open 2024 for Chinese Foundation",
        category: 'Classical Scene',
        link: 'https://www.classical-scene.com/2024/01/10/unnamed-trio/',
    },
    {
        images: 'juilliard-live.png',
        title: "US Premiere of Widmann Violin Concerto No. 2 with Stella Chen and the Juilliard Orchestra",
        category: 'Juilliard Live',
        link: 'https://www.juilliard.live/videos/jorg-widmann-violin-concerto-no-2',
    },
    {
        
        images: 'stella_gramophone_1.png',
        title: "Stella Chen wins Gramophone Young Artist of the Year Award",
        category: 'Classic FM',
        link: 'https://www.classicfm.com/events/gramophone-awards/stella-chen-young-artist-watch-ceremony/',
    },
    {
        images: 'newport-advance-cropped.jpeg',
        title: "Newport Seasons Advance",
        category: 'The Boston Musical Intelligencer',
        link: 'https://www.classical-scene.com/2023/06/11/newport-advance/',
    },
    {
        images: 'textura_cropped.jpeg',
        title: "Stella x Schubert Textura Review",
        category: 'Textura Review',
        link: 'https://www.thestrad.com/reviews/stella-chen-schubert/16259.article',
    },
    {
        images: 'stringsmagazine.png',
        title: "Magic Kingdom: Strings Magazine", 
        category: 'Strings Magazine',
        link: {StringsMagazinePDF},
    },
    {
        images: 'cover_artwork.png',
        title: "Stella Chen: Schubert",
        category: 'The Strad',
        link: 'https://www.thestrad.com/reviews/stella-chen-schubert/16259.article',
    },
    {
        images: 'therehearsalstudio.jpeg',
        title: "Stella Chen Brings Schubert to CMSF",
        category: 'The Rehearsal Studio',
        link: 'https://therehearsalstudio.blogspot.com/2023/03/stella-chen-brings-schubert-to-cmsf.html',
    },
    {
        images: 'gramophone.jpeg',
        title: "The Power of Schubert’s Fantasie",
        category: 'Gramophone',
        link: 'https://www.gramophone.co.uk/blogs/article/the-power-of-schubert-s-fantasie',
    },
    {
        images: 'strad.png',
        title: 'Schubert transports me to another world: violinist Stella Chen',
        category: 'The Strad',
        link: 'https://www.thestrad.com/video/schubert-transports-me-to-another-world-violinist-stella-chen/16186.article',
    },
    {
        images: 'cover_artwork.png',
        title: 'VC Artist Violinist Stella Chen Releases Debut Album, "Stella x Schubert"',
        category: 'The Violin Channel',
        link: 'https://theviolinchannel.com/vc-artist-violinist-stella-chen-releases-debut-album-stella-x-schubert/',
    },
    /*
    {
        images: 'cover_artwork.png',
        title: 'Violinist Stella Chen Releases Debut Album, STELLA X SCHUBERT',
        category: 'Broadway World Classical',
        link: 'https://www.broadwayworld.com/bwwclassical/article/Violinist-Stella-Chen-Releases-Debut-Album-STELLA-X-SCHUBERT-Single-Out-Today-20230227',
    },
    */
    {
        images: 'baltimore_symphony_orchestra.jpeg',
        title: 'Stella Chen Debuts with the Baltimore Symphony Orchestra',
        category: 'BSO Press Release',
        link: 'https://www.bsomusic.org/baltimore-symphony-orchestra-celebrates-the-lunar-new-year-with-inaugural-concert/',
    },
    {
        images: 'bag_cropped.jpeg',
        title: 'Graziella Braccialini \"Violino\" Bag Announces Collaboration with Stella Chen',
        category: 'Arezzo Notizie',
        link: 'https://www.arezzonotizie.it/economia/incontro-moda-nume-festival-talento-straordinario.html',
    },
    {
        images: 'nyphil_cropped.jpeg',
        title: 'Inside the Annual Lunar New Year Gala',
        category: 'Vogue',
        link: 'https://www.vogue.com/slideshow/new-york-philharmonic-lunar-new-year-gala-2022',
    },
    {
        images: 'minnesotanycelebration_cropped.png',
        title: 'Stella Chen Debuts with the Minnesota Orchestra',
        category: 'The Violin Channel',
        link: 'https://www.theviolinchannel.com/new-to-youtube-vc-artist-stella-chen-performs-with-minnesota-orchestra/',
    },
    {
        images: 'lipman-chen_cropped.jpeg',
        title: 'Stella Chen Debuts with the Chicago Symphony',
        category: 'The Violin Channel',
        link: 'https://www.theviolinchannel.com/new-to-youtube-vc-artist-stella-chen-performs-with-minnesota-orchestra/',
    },
]

export default BlogContent;