import { Scrollspy } from "@makotot/ghostui";
import React, { Component, useRef } from "react";
import { FaInstagram, FaFacebookF, FaSpotify, FaItunes } from "react-icons/fa";
import { FiX, FiMenu } from "react-icons/fi";
import { Link, animateScroll as scroll } from "react-scroll";

const SocialShare = [
    { Social: <FaSpotify />, link: 'https://open.spotify.com/artist/1TU4PJ9ushekhl6vPo37Jl' },
    { Social: <FaInstagram />, link: 'https://www.instagram.com/stellafaychen' },
    { Social: <FaFacebookF />, link: 'https://www.facebook.com/stellafaychen/' },
    { Social: <FaItunes />, link: 'https://music.apple.com/us/artist/stella-chen/1179484533' },
]
class HeaderThree extends Component {
    constructor(props) {
        super(props);
        this.menuTrigger = this.menuTrigger.bind(this);
        this.CLoseMenuTrigger = this.CLoseMenuTrigger.bind(this);
        this.stickyHeader = this.stickyHeader.bind(this);
        this.closeSidebar = this.closeSidebar.bind(this);

        //  this.subMetuTrigger = this.subMetuTrigger.bind(this);
        window.addEventListener('load', function () {
            console.log('All assets are loaded');
        })
    }


    menuTrigger() {
        document.querySelector('.header-wrapper').classList.toggle('menu-open')
    }

    CLoseMenuTrigger() {
        document.querySelector('.header-wrapper').classList.remove('menu-open')
    }

    closeSidebar() {
        document.querySelector('.header-wrapper').classList.remove('menu-open')
    }

    stickyHeader() { }

    render() {

        window.addEventListener('scroll', function () {
            var value = window.scrollY;
            if (value > 100) {
                document.querySelector('.header--fixed').classList.add('sticky')
            } else {
                document.querySelector('.header--fixed').classList.remove('sticky')
            }
        });

        var elements = document.querySelectorAll('.has-droupdown > a');
        for (var i in elements) {
            if (elements.hasOwnProperty(i)) {
                elements[i].onclick = function () {
                    this.parentElement.querySelector('.submenu').classList.toggle("active");
                    this.classList.toggle("open");
                }
            }
        }
        const { logo, color = 'default-color' } = this.props;
        let logoUrl;
        if (logo === 'light') {
            logoUrl = <img src="assets/images/logo/logo-light.png" alt="Digital Agency" />;
        } else if (logo === 'dark') {
            logoUrl = <img src="assets/images/logo/logo-dark.png" alt="Digital Agency" />;
        } else if (logo === 'symbol-dark') {
            logoUrl = <img src="assets/images/logo/logo-symbol-dark.png" alt="Digital Agency" />;
        } else if (logo === 'symbol-light') {
            logoUrl = <img src="assets/images/logo/logo-symbol-light.png" alt="Digital Agency" />;
        } else {
            logoUrl = <img src="assets/images/logo/logo.png" alt="Digital Agency" />;
        }

        return (
            <header className={`header-area header-style-two header--fixed ${color}`}>
                <div className="header-wrapper">
                    <div className="header-left d-flex align-items-center">
                        {/* TC */}
                        {/* <div className="logo">
                            <a href={this.props.homeLink}>
                                {logoUrl}
                            </a>
                        </div> */}
                        <nav className="mainmenunav d-lg-block ml--50">
                            <ul className="mainmenu">
                                <li> <Link className="head-nav-link" activeClass="is-current" to="home" spy={true} smooth={true} duration={500} onClick={this.closeSidebar}>Home</Link></li>
                                <li><Link className="head-nav-link" activeClass="is-current" to="stellaxschubert" spy={true} smooth={true} duration={500} onClick={this.closeSidebar}>Stella x Schubert</Link></li>
                                <li><Link className="head-nav-link" activeClass="is-current" to="biography" spy={true} smooth={true} duration={500} onClick={this.closeSidebar}>Biography</Link></li>
                                <li><Link className="head-nav-link" activeClass="is-current" to="concerts" spy={true} smooth={true} duration={500} onClick={this.closeSidebar}>Concerts</Link></li>
                                <li><Link className="head-nav-link" activeClass="is-current" to="news" spy={true} smooth={true} duration={500} onClick={this.closeSidebar}>News</Link></li>
                                <li><Link className="head-nav-link" activeClass="is-current" to="contact" spy={true} smooth={true} duration={500} onClick={this.closeSidebar}>Contact</Link></li>
                            </ul>
                        </nav>
                    </div>
                    <div className="header-right">
                        <div className="social-share-inner">
                            <ul className="social-share social-style--2 color-black d-flex justify-content-start liststyle">
                                {SocialShare.map((val, i) => (
                                    <li key={i}><a href={`${val.link}`}>{val.Social}</a></li>
                                ))}
                            </ul>
                        </div>
                        <div className="header-btn">
                            {/* TC */}
                            <a className="rn-btn" href="https://platoon.lnk.to/stellaxschubert">
                                <span>Listen Now</span>
                            </a>
                        </div>
                        {/* Start Humberger Menu  */}
                        <div className="humberger-menu d-block d-lg-none pl--20">
                            <span onClick={this.menuTrigger} className="menutrigger text-white"><FiMenu /></span>
                        </div>
                        {/* End Humberger Menu  */}
                        <div className="close-menu d-block d-lg-none">
                            <span onClick={this.CLoseMenuTrigger} className="closeTrigger"><FiX /></span>
                        </div>
                    </div>
                </div>
            </header>
        )
    }
}
export default HeaderThree;